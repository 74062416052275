import styled from 'styled-components'

export default styled.div`
  .no-bar-data {
    width: 100%;
    height: 100%;
    font-family: 'Prompt-Medium';
    font-size: 18px;
  }
  .recharts-legend-wrapper {
    .legend-wrapper {
      display: flex;
      justify-content: center;
      position: relative;
      left: 24px;
      width: 100%;
      margin-top: 20px;
      .legend-container {
        margin-right: 8px;
        .each-legend-wrapper {
          .legend-color {
            width: 8px;
            height: 8px;
            margin-right: 4px;
          }
          .legend-datakey {
            font-size: 12px;
          }
        }
      }
    }
  }
  .recharts-responsive-container {
    .recharts-wrapper {
      .forecast-yAxis-label {
        font-size: 12px;
      }
    }
  }
  .recharts-tooltip-wrapper {
    background: none;
    .tooltip-wrapper {
      background-color: rgba(0, 0, 0, 0.5);
      padding: 6px;
      .tooltip-value-container {
        display: flex;
        .value-container {
          display: flex;
          align-items: center;
          &.forecast {
            margin-left: 8px;
          }
          .color-label {
            width: 10px;
            height: 10px;
            border-radius: 50%;
            margin-right: 10px;
          }
          .data-label {
            font-size: 12px;
          }
        }
      }
      .time-label-wrapper {
        font-size: 16px;
      }
    }
  }
`
