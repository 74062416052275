import React from 'react'
import PropTypes from 'prop-types'
import VerEx from 'verbal-expressions'
import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis, Legend, CartesianGrid, Tooltip } from 'recharts'
import CardWrapper from '../CardWrapper/dynamic'
import SkeletonAnimation from '../SkeletonAnimation/dynamic'
import TrafficForecastStyled from './styled'
import { capitalize, chunkArray } from '../../utils'

import { withTranslation } from '../../../i18n'

const tickStyled = {
  fontFamily: 'Prompt',
  fontSize: 12,
  fill: '#fff',
}

export class TrafficForecast extends React.PureComponent {
  renderLegend = ({ payload }) => {
    let legendContainers = []
    if (payload) {
      const withoutForecastList = payload.filter(each => !this.isForecast(each.value))
      const initalForecastLegendData = {
        dataKey: 'forecast',
        value: 'forecast',
      }
      const preparedListLegend = chunkArray([...withoutForecastList, initalForecastLegendData], 2)
      legendContainers = preparedListLegend.map((arrayChunkLegend, index) => {
        const legends = arrayChunkLegend.map(legend => {
          return (
            <div className="each-legend-wrapper flex align-items-center" key={`${legend.value}`}>
              <div className="legend-color" style={{ backgroundColor: this.props.barColors[legend.dataKey] }} />
              <div className="legend-datakey">{`${capitalize(this.props.t(legend.dataKey))}`}</div>
            </div>
          )
        })
        return (
          <div key={index} className="legend-container">
            {legends}
          </div>
        )
      })
    }
    return <div className="legend-wrapper">{legendContainers}</div>
  }

  isForecast(text) {
    const regexForecast = VerEx().find('forecast')
    return regexForecast.test(text.toLowerCase())
  }

  isRawForecast(text) {
    const regexRawForecast = VerEx().find('forecast raw')
    return regexRawForecast.test(text.toLowerCase())
  }

  renderTooltip = ({ payload }) => {
    let tooltipData = []
    let label = ''
    if (payload) {
      const listDataKey = payload.filter(legendData => !this.isForecast(legendData.dataKey))
      const listDataKeyForecast = payload.filter(legendData => this.isForecast(legendData.dataKey))
      const preparedListDataKeyWithForecast = listDataKeyForecast.map(forecastLegendData => {
        const regexForecast = VerEx().find('forecast')
        const keyToFind = forecastLegendData.dataKey.replace(regexForecast, '').trim()
        const matchKeyData = listDataKey.find(legendData => legendData.dataKey === keyToFind)
        return {
          legendData: matchKeyData,
          forecastData: forecastLegendData,
        }
      })
      tooltipData = preparedListDataKeyWithForecast.map(eachKeyData => {
        label = eachKeyData.legendData.payload.label
        const forecastFinalValue = eachKeyData.forecastData.payload[`${eachKeyData.forecastData.dataKey} raw`]
        return (
          <div className="tooltip-value-container flex" key={eachKeyData.legendData.dataKey}>
            <div className="value-container">
              <div className="color-label" style={{ backgroundColor: eachKeyData.legendData.color }} />
              <div className="data-label">{`${capitalize(this.props.t(eachKeyData.legendData.dataKey))}: ${eachKeyData.legendData.value}`}</div>
            </div>
            <div className="value-container forecast">
              <div className="color-label" style={{ backgroundColor: eachKeyData.forecastData.color }} />
              <div className="data-label">{`${capitalize(this.props.t(eachKeyData.forecastData.dataKey))}: ${forecastFinalValue}`}</div>
            </div>
          </div>
        )
      })
    }
    return (
      <div className="tooltip-wrapper flex flex-column">
        <div className="time-label-wrapper">{label}</div>
        {tooltipData}
      </div>
    )
  }

  renderBars() {
    let output = null
    if (this.props.data.length > 0) {
      const preparedListKey = Object.keys(this.props.data[0]).filter(key => key !== 'label' && !this.isRawForecast(key))
      output = preparedListKey.map(dataKey => {
        const regexForecast = VerEx().find('forecast')
        const preparedStackKey = dataKey.toLowerCase().replace(regexForecast, '').trim()
        const isForecast = this.isForecast(dataKey)
        const barColor = isForecast ? this.props.barColors.forecast : this.props.barColors[dataKey]
        return <Bar key={dataKey} barSize={8} dataKey={dataKey} stackId={preparedStackKey} fill={barColor} legendType="none" />
      })
    }
    return output
  }

  checkNoData() {
    const listBooleanNoData = this.props.data.map(data => {
      const isNoData = Object.keys(data)
        .filter(key => key !== 'label' && !this.isRawForecast(key))
        .reduce((prev, currentKey) => {
          return prev && data[currentKey] === 0
        }, true)
      return isNoData
    })
    return listBooleanNoData.every(truthy => truthy)
  }

  getYAxisLabel(text) {
    return (
      <text x={60} y={32} fill="#fff" textAnchor="middle" className="forecast-yAxis-label">
        {text}
      </text>
    )
  }

  renderBarChart() {
    let output = null
    if (this.props.loading) {
      output = (
        <div className="no-bar-data flex justify-center align-items-center">
          <SkeletonAnimation id="traffic-forecast-skeleton-loading" className="loading" />
        </div>
      )
    } else if (this.checkNoData()) {
      const noData = this.props.t('no_data')
      output = <div className="no-bar-data flex justify-center align-items-center">{noData}</div>
    } else {
      output = (
        <ResponsiveContainer width={'100%'} height={'100%'}>
          <BarChart data={this.props.data} margin={{ top: 60, left: 0, bottom: 24, right: 45 }} barGap={1}>
            <CartesianGrid x={60} vertical={false} style={{ opacity: 0.3, width: '100%' }} />
            <YAxis
              label={this.getYAxisLabel(this.props.t('vehicles'))}
              axisLine={false}
              type="number"
              tickLine={false}
              tick={tickStyled}
              allowDecimals={false}
            />
            <XAxis dataKey="label" axisLine={false} interval={0} tickLine={false} tick={tickStyled} tickMargin={13} />
            {this.renderBars()}
            <Legend verticalAlign="bottom" iconSize={14} content={this.renderLegend} />
            <Tooltip cursor={false} content={this.renderTooltip} />
          </BarChart>
        </ResponsiveContainer>
      )
    }
    return output
  }

  checkMessage(message, val) {
    return message === '' ? val : message
  }

  renderContent() {
    return (
      <CardWrapper title={this.props.t('traffic_forecast')} id="header-traffic-forecast">
        {this.renderBarChart()}
      </CardWrapper>
    )
  }

  render() {
    return (
      <TrafficForecastStyled
        ref={con => {
          this.cardContainer = con
        }}
        className={this.props.className}
      >
        {this.renderContent()}
      </TrafficForecastStyled>
    )
  }
}

TrafficForecast.defaultProps = {
  loading: false,
}

TrafficForecast.propTypes = {
  t: PropTypes.func.isRequired,
  className: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
    }),
  ).isRequired,
  barColors: PropTypes.object,
  loading: PropTypes.bool,
}

export default withTranslation()(TrafficForecast)
